<template>
    <div id="about">
        <PageTitle :title="title" />
        <div id="agent">
            <div class="profilePhoto">
                <img id="agentPhoto" :src="img" alt="Hannah DelPonte Vollera"/>
            </div>
            <div class="content">
                <p v-html="content"></p>
            </div>
        </div>
        <div id="aboutTestimonial">
            <h2>
                Testimonials
            </h2>
            <flickity :options="flickityOptions" id="testimonialSlides">
                <div class="carousel-cell testimonialSlide" v-for="(slide, i) in slides" :key="i">
                    <div class="content">
                        <p class="message">
                            "{{slide.content}}"
                        </p>
                        <p class="author"> 
                            -{{ slide.author }}
                        </p>
                    </div>
                </div>
            </flickity>
        </div>
    </div>
</template>

<script>
import PageTitle from "../components/PageTitle"
import Flickity from 'vue-flickity';

export default {
    name: "About",
    components: {
        PageTitle,
        Flickity
    },
    data(){
        return{
            title: "About",
            description: {
                paragraph1: `Established in 1911, Douglas Elliman Real Estate is the largest brokerage in the New York Metropolitan area and the second largest independent residential real estate brokerage in the United States by sales volume. With more than 7,000 agents, the company operates approximately 120 offices in New York City, Long Island, The Hamptons, Westchester, Connecticut, New Jersey, Florida, California, Colorado, Massachusetts and Texas.`,
                paragraph2: `Moreover, Douglas Elliman has a strategic global alliance with London-based Knight Frank Residential for business in the worldwide luxury markets spanning 60 countries and six continents. The company also controls a portfolio of real estate services including Douglas Elliman Development Marketing, Douglas Elliman Property Management and Douglas Elliman Commercial. `
            },
            content: `Hannah DelPonte Vollera brings passion, joy, enthusiasm and experience into the world of luxury -real estate. Through her experience in the industry she has developed many valuable relationships with many buyers and sellers and investors. Hannah specializes in coastal properties and has a niche in Los Angeles westside neighborhoods. Hannah started her career in luxury high-rise condominium communities of Azzurra, Cove and Regatta in Marina del Rey and loves working in the coastal community.<br><br>
            With over Twenty-one years of experience in the Real Estate Industry she is a broker you can truly trust. She has worked as a property manager, leasing and sales consultant for many developers such as Lincoln Properties, Colony Capital, The Sunshine Group, Coldwell Banker, Prudential California. She was named top 1% Berkshire Hathaway Home Services Nationwide. She now is named one of the top producing agents at Compass. She loves the cutting edge of technology that Compass offers her clientele.<br><br>
            She has sold, leased and managed over 2500 homes in Los Angeles. She specializes in many markets including Marina del Rey, Playa Del Rey, Playa Vista, Venice, Santa Monica, Pacific Palisades, Manhattan Beach, Malibu, Beverly Hills and many more communities all over Los Angeles. Her relationships in the industry give Hannah and her clients an advantage of coming soon listings, off market sales, short sales, trust sales and foreclosures. She also works with many investors, relocation companies and design firms to help with all of her clients specific needs.<br><br>
            Hannah attended the Fashion Institute of Design and Merchandising where she majored in international marketing, communications and interior design. She knows and appreciates detail, design and architecture, which are all great assets to have when looking for a well respected and knowledgeable agent. She also understands that listening to her buyers and sellers' wants, needs, dreams and desires are critical in listing and selling property. Her background and experience in international marketing gives her clients the cutting edge to position their property to a diverse clientele in today's high tech world.<br><br>
            Hannah's passion for communication with people and her relentless desire to provide a smooth, organized and favorable outcome is why her clients continue to come back. She has lived in Los Angeles for over 28 years and loves to give back to the community by participating in many Charity organizations such as Harvest Home to help homeless mothers with their children and the community.  She looks forward to working with you and is here to help with all your real estate wants and needs. The best complement and true measure of success is the consistent return clientele and referral base through her clients!<br><br>
            The Hannah Group hopes to be a part of your real estate future.

            `,
            img: "/images/site_owner.jpg",
            slides: [
                {
                    content: "Hannah is the greatest. She helped us through our first time home buying experience. We bought our home in Redondo Beach after Hannah took us around to a few different places. We asked her to take us to see homes last minute, and she was really accommodating. We were able to smoothly buy our dream home without any issues (and below the asking price). She is responsive, attentive, and a smart negotiator.",
                    author: "Dione"
                },
                {
                    content: "Hannah DelPonte has gone beyond to make sure my wife and I have been comfortable through the stressful process of buying and selling a home. She has done extra things for us knowing our time was a bit limited after we had our newborn 4 1/2 months ago. She helped us find a great house and also quickly sold two of our places. Hannah is extremely professional, warm and very attentive. I highly recommend Hannah!",
                    author: "Client"
                },
                {
                    content: `Hannah is honestly the best of the best. She is so good at her job it feels like your business is being handled by an old family friend. She is super considerate of her clients time, she makes every meeting and appointment exactly on time and she gets her job done without disrupting your life or your schedule. In a market full of aggressive, profit driven salespeople, Hannah is a breath of fresh air. Despite her uncanny ability to deliver a superior bottom line result, she always has a caring and human approach to everyone she deals with. Pressure and stress are never an issue when Hannah is involved; and she is able to keep everyone informed at all times. If you are buying or selling and you don't call Hannah you are just plain nuts.`,
                    author: "Client"
                },
                {
                    content: `Hannah was extraordinarily helpful with our new home. She has excellent follow-through, attention to detail, stays true to her word, and goes above and beyond to ensure you are pleased. I would recommend anyone looking for a new home to work with her!`,
                    author: "Kim"
                },
                {
                    content: `It is almost impossible to list the reasons why this real estate agent surpasses excellence. Initially managing my home for me when I began to do some traveling and decided to rent it out, eventually when the decision came to sell, it was natural to list with Hannah DelPonte. Every step of the way, as both property manager and then listing agent, Hannah has gone above and beyond in ensuring my needs were met to the utmost. I felt that all through the sale of my property we worked together as a 'team' - she took on board any notes or thoughts I had, and I was always eager to hear hers, which unwaveringly had my own best interests at heart! My home sold for $8,000 over asking and much of the credit goes to the exceptional people skills of this fine realtor. Hats of Hannah!! :)`,
                    author: "Natalie"
                },
            ],
            flickityOptions: {
                wrapAround: true,
                adaptiveHeight: true,
                prevNextButtons: false,
                selectedAttraction: 0.2,
                friction: 0.8
            }
        }
    },
    metaInfo: {
        title: 'About | Hannah DelPonte Vollera',
        meta: [{
            name: 'description', 
            content: "Westside & Beach Cities Luxury Real Estate."
        }]
    },
}
</script>

<style lang="scss" scoped>

#agent{
    display: flex;
    flex-direction: row;
    align-items: center;
    .content{
        flex: 1;
        text-align: left;
        p{
            margin: 0 10%;
        }
    }
    .profilePhoto{
        width: 25%;
        img{
            object-fit: cover;
            width: 100%;
            display: block;
        }
    }
    margin-bottom: 3rem;
}

#description{
    text-align: center;
    padding-bottom: 6rem;
    #logo{
        width: 30%;
        margin: 4rem 0;
    }
    .box{
        text-align: left;
        display: flex;
        flex-flow: wrap;
        p{
            letter-spacing: 1.2px;
        }
        .box-cell{
            flex: 0 0 50%;
        }
        #paragraph1{
            p{
                margin: 0 2rem 0 10%;
            }
        }
        #paragraph2{
            p{
                margin: 0 10% 0 2rem;
            }
        }
    }
}
#aboutTestimonial{
    text-align: center;
    background: url("/images/bg-explore.jpg") no-repeat center center fixed;
    background-size: cover;
    padding-top: 3rem;
    padding-bottom: 5.5rem;
    h2{
        text-align: center;
        margin: 3.2rem auto;
        font-size: 2.55rem;
        font-weight: 500;
    }
}
#testimonialSlides{
    width: 100%;
    .testimonialSlide{
        width: 100%;
        .content{
            text-align: center;
            margin: 0 15%;
            display: block;
            .message{
                padding: 2rem 0;
            }
            .author{
                text-transform: uppercase;
                font-weight: 600;
                padding-bottom: 1rem;
            }
        }
    }
}
@media (max-width: 780px) { 
    .profilePhoto{
        margin-right: 1rem;
    }
}


@media (max-width: $mobile) { 
    #agent{
        flex-wrap: wrap;
        text-align: center;
        .profilePhoto{
            width: 80%;
            margin: 2rem auto;
            #agentPhoto{
                object-fit: cover;
                object-position: 0 0;
                height: 300px;
            }
        }
        .content{
            text-align: center;
            padding-bottom: 3rem;
        }
    }
    #aboutTestimonial{
        padding: 1rem 0;
        padding-bottom: 3rem;
        background-size: cover;
        background-attachment: unset;
        background-position: center;
        background-repeat: no-repeat;
    }
    #testimonialSlides{
        width: 100%;
        .testimonialSlide{
            width: 100%;
            .content{
                text-align: center;
                margin: 0.5rem 2rem;
                display: block;
            }
        }
    }
    #description{
        #logo{
            width: 80%;
        }
        .box{
            display: block;
            text-align: center;
            #paragraph1{
                p{
                    margin: 0.5rem 2rem;
                    margin-bottom: 1.5rem;
                    letter-spacing: 1px;
                }
            }
            #paragraph2{
                p{
                    margin: 0.5rem 2rem;
                    letter-spacing: 1px;
                }
            }
        }
    }
}
</style>

<style>
#testimonialSlides .flickity-button{
    background: #000;
}

#testimonialSlides .flickity-page-dots .dot{
    width: 7px;
    height: 7px;
}

#testimonialSlides .flickity-prev-next-button {
  width: 30px;
  height: 30px;
}

#testimonialSlides .flickity-button-icon {
  fill: white;
}
</style>